@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'AcuminPro';
    src: url('/fonts/Acumin-Pro-Book.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'AcuminPro';
    src: url('/fonts/Acumin-Pro-Medium.woff2') format('woff2');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'AcuminPro';
    src: url('/fonts/Acumin-Pro-Bold.woff2') format('woff2');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'AcuminPro';
    src: url('/fonts/Acumin-Pro-Black.woff2') format('woff2');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  @font-face {
    font-family: 'GTSuperText-Black';
    src: url('/fonts/GTSuperText-Black.woff2') format('woff2');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }
  html {
    scroll-behavior: smooth !important;
  }
  h1,
  .h1 {
    font: 4.25rem/5rem 'GTSuperText-Black';
    font-style: normal;
    font-weight: 900;
    color: black;
  }
  h2,
  .h2 {
    font: 2.5rem/3rem 'GTSuperText-Black';
    color: black;
    font-style: normal;
    font-weight: 900;
    word-wrap: break-word;
  }
  h3,
  .h3 {
    font: 2rem/2.5rem 'GTSuperText-Black';
    color: black;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-weight: 900;
    margin: 1.5rem 0 0.5rem 0;
  }
  h4,
  .h4 {
    font: 1.625rem/1.75rem 'AcuminPro';
    font-weight: 500;
    color: black;
    margin: 1.5rem 0 0.5rem 0;
  }
  h5,
  .h5 {
    font: 1.5rem/1.875rem 'AcuminPro';
    color: black;
    font-style: normal;
    font-weight: 500;
  }
  h6,
  .h6 {
    font: 1.0625rem/1.5 'AcuminPro';
    color: black;
    font-style: normal;
    font-weight: 500;
    margin: 1.5rem 0 0.5rem 0;
  }
  p {
    font: 1.0625rem/1.5rem 'AcuminPro';
    color: black;
    font-style: normal;
    min-height: 1.375rem;
  }

  .code-of-conduct h2 {
    margin-top: 4rem;
  }
  .code-of-conduct h5 {
    margin-top: 1rem;
  }

  .code-of-conduct p {
    margin-top: 0.5rem;
  }

  .code-of-conduct a,
  .landing-rich-text a:not(.green-button) {
    color: #3cd52e;
    text-decoration: underline;
  }

  .more-info {
    font: 0.875rem/1.25rem 'AcuminPro';
    color: var(--brand-deep-blue, #0a0a33);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.0125rem;
    text-transform: uppercase;
    transition: underline 0.3s ease-in;
  }

  .landing-page .Accordion p {
    width: 70%;
  }

  .gray-text-children > p {
    color: #2c2c2c;
  }

  @keyframes underline {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  @keyframes group-underline-animation-reverse {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }

  .single-underline-animation {
    position: relative;
    color: currentColor;
    text-decoration: none;
    transition: color 0.3s ease-in;
    width: max-content;
    display: inline-block;
  }

  .single-underline-animation::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.3s ease-in;
  }

  .single-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  .show-more {
    font: 1.0625rem/1.25rem 'AcuminPro';
    color: var(--brand-deep-blue, #0a0a33);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.0125rem;
    text-transform: uppercase;
  }

  ul {
    list-style-type: square;
    margin-left: 30px;
    letter-spacing: normal;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .landing-rich-text {
    margin-top: 5rem;
  }

  .landing-rich-text.landing-page > p,
  .landing-rich-text.landing-page > ul {
    width: 70%;
  }

  .landing-rich-text:last-child {
    margin-bottom: 0;
  }

  .landing-rich-text h2 {
    margin: 5rem 0 1.5rem 0;
  }

  .landing-rich-text .awards h2,
  .landing-rich-text .CardSection h2,
  .landing-rich-text .CardSection h4,
  .landing-rich-text .Quote h2 {
    margin: 0;
  }

  .landing-rich-text h4,
  .landing-rich-text h5 {
    margin: 1.5rem 0 0.5rem 0;
  }

  .landing-rich-text .PictureAndTextInRow h2,
  .landing-rich-text .PictureAndTextInRow h5,
  .landing-rich-text .Accordion h5 {
    margin-top: 0;
    padding: 0;
  }

  .landing-rich-text .Accordion h2,
  .landing-rich-text .Accordion h5 {
    margin: 0;
  }

  .landing-rich-text .PictureAndTextInRow p {
    padding: 0;
  }

  .landing-rich-text .PictureAndTextInRow p:not(:last-child) {
    margin-bottom: 1rem;
  }

  .landing-rich-text li {
    margin-top: 0.5rem;
  }

  .HeroBanner > h1 {
    margin-bottom: 1.5rem;
  }

  .HeroBanner p {
    font: 1.25rem/1.875rem 'AcuminPro';
  }
  .text-white p {
    color: white;
  }

  .font-bold p {
    font-weight: 700;
  }

  .video-container video {
    max-height: 750px;
  }

  .player-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .react-player {
    width: 100%;
    height: 100%;
  }

  .react-thumbnail-generator {
    width: 100%;
    height: 100%;
  }

  .thumbnail-container {
    height: 100%;
  }

  .thumbnail-container .child {
    height: fit-content;
  }

  .thumbnail-container canvas {
    width: 100%;
    max-height: 750px;
  }

  .thumbnail-container video {
    width: 100%;
  }

  .thumbnail-container video {
    display: none;
  }
  .job-page-content img {
    height: 450px;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    margin-bottom: 3rem;
  }

  .job-page-content strong {
    font: 2.5rem/3rem 'GTSuperText-Black';
  }
  .job-page-content li {
    font: 1.0625rem/1.375rem 'AcuminPro';
    color: black;
    font-style: normal;
    min-height: 1.375rem;
  }
  .green-button {
    font-family: var(--AcuminProBold);
    font-weight: bold;
    line-height: 1.18;
    text-align: center;
    cursor: pointer;
    top: 0;
    left: 0;
    transition: all 0.1s linear 0s;
    position: relative;
    display: inline-block;
    /* padding: 15px 25px; */
    background-color: #fff;
    text-transform: uppercase;
    color: #000;
    letter-spacing: normal;
    /* box-shadow: -6px 6px 0 #3cd52e; */
    box-shadow: -1px 0px 0px #3cd52e, -0px 1px 0px #3cd52e, -2px 1px 0px #3cd52e,
      -1px 2px 0px #3cd52e, -3px 2px 0px #3cd52e, -2px 3px 0px #3cd52e,
      -4px 3px 0px #3cd52e, -3px 4px 0px #3cd52e, -5px 4px 0px #3cd52e,
      -4px 5px 0px #3cd52e, -6px 5px 0px #3cd52e, -5px 6px 0px #3cd52e,
      -7px 6px 0px #3cd52e, -6px 7px 0px #3cd52e, -8px 7px 0px #3cd52e,
      -7px 8px 0px #3cd52e, -9px 8px 0px #3cd52e;
    border: 3px solid #3cd52e;
    text-decoration: none;
  }

  .green-button:disabled {
    box-shadow: -1px 0px 0px #aeaeae, -0px 1px 0px #aeaeae, -2px 1px 0px #aeaeae,
      -1px 2px 0px #aeaeae, -3px 2px 0px #aeaeae, -2px 3px 0px #aeaeae,
      -4px 3px 0px #aeaeae, -3px 4px 0px #aeaeae, -5px 4px 0px #aeaeae,
      -4px 5px 0px #aeaeae, -6px 5px 0px #aeaeae, -5px 6px 0px #aeaeae,
      -7px 6px 0px #aeaeae, -6px 7px 0px #aeaeae, -8px 7px 0px #aeaeae,
      -7px 8px 0px #aeaeae, -9px 8px 0px #aeaeae;
    border: 3px solid #aeaeae;
    pointer-events: none;
  }

  .green-button:disabled p {
    color: #aeaeae;
  }

  .contact-rectangle {
    display: inline-block;
  }

  .green-button p {
    font-family: var(--AcuminProBold);
    padding: 15px 25px 15px 25px !important;
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #000;
  }

  .green-button:hover {
    color: #3cd52e;
  }

  .green-button::after {
    transition: all 0.1s linear 0s;
    content: '';
    position: absolute;
    top: 0px;
    left: -7px;
    width: 8px;
    height: 8px;
    background-color: #3cd52e;
    transform: rotate(45deg);
    z-index: -1;
  }

  .green-button:focus-within::after {
    z-index: -1;
    display: none;
  }

  .green-button::before {
    transition: all 0.1s linear 0s;
    content: '';
    position: absolute;
    bottom: -8px;
    right: 0px;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    z-index: -1;
  }

  a.green-button {
    position: relative;
  }

  .green-button:active {
    top: 6px;
    left: -6px;
    box-shadow: -1px 0px 0px #3cd52e, -0px 1px 0px #3cd52e, -2px 1px 0px #3cd52e,
      -1px 2px 0px #3cd52e, -3px 2px 0px #3cd52e, -2px 3px 0px #3cd52e,
      -4px 3px 0px #3cd52e;
  }
  video {
    object-fit: cover;
    padding: 0;
    margin: 0;
  }
  .player-container .react-player__shadow {
    background: #0a0a33;
    border-radius: 0;
  }
  .player-container {
    position: relative;
    width: 100%;
    height: 330px;
  }
  .player-container img {
    height: 330px;
    object-fit: cover;
  }

  .Jobs h2 span {
    padding-left: 0.5rem;
  }

  .expanding-shadow {
    position: absolute;
    top: -13px !important;
    left: -13px !important;
    box-shadow: 7px 7px 0px#3cd52e, 8px 8px 0px #3cd52e, 9px 9px 0px #3cd52e,
      10px 10px 0px #3cd52e, 11px 11px 0px #3cd52e, 12px 12px 0px #3cd52e;
  }

  .border-gray {
    border: 1px solid #f5f5f5;
  }

  .quote-container {
    padding: 80px 120px 80px 120px;
  }

  .slider-text {
    height: 130px;
  }

  .slider-negative-magrin {
    margin-left: -5vmin;
    margin-right: -5vmin;
  }

  .slider-text-mobile-padding {
    padding-left: 5vmin;
    padding-right: 5vmin;
  }

  .cube {
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    background-color: #fff;
    cursor: pointer;
  }

  .cube.green {
    background-color: #3cd52e;
  }

  @media (min-width: 1700px) {
    h1,
    .h1 {
      font: 4.3rem/4.8rem 'GTSuperText-Black';
      color: black;
    }
    h2,
    .h2,
    .job-page-content strong {
      font: 2.8rem/3.3rem 'GTSuperText-Black';
      color: black;
      font-style: normal;
      font-weight: 900;
    }

    h3,
    .h3 {
      font: 2.3rem/2.8rem 'GTSuperText-Black';
      color: black;
      font-feature-settings: 'clig' off, 'liga' off;
      font-style: normal;
      font-weight: 900;
    }
    h4,
    .h4 {
      font: 1.8rem/2.2rem 'AcuminPro';
      color: black;
    }
    h5,
    .h5,
    .h5 p {
      font: 1.5rem/1.7rem 'AcuminPro';
      font-style: normal;
    }
    p,
    .job-page-content li {
      font: 1.2rem/1.8rem 'AcuminPro';
      color: black;
      font-style: normal;
    }

    .more-info {
      font: 1.2rem/1.7rem 'AcuminPro';
      color: var(--brand-deep-blue, #0a0a33);
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.017rem;
      text-transform: uppercase;
    }

    .show-more {
      font: 1.3rem/1.8rem 'AcuminPro';
      color: var(--brand-deep-blue, #0a0a33);
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.017rem;
      text-transform: uppercase;
    }

    .HeroBanner p {
      font: 1.4rem/1.8rem 'AcuminPro';
    }

    .ArticleLanding p {
      font: 1.0625rem/1.375rem 'AcuminPro';
    }
  }

  @media (min-width: 500px) {
    .slider-text {
      height: 120px;
    }
  }

  @media (min-width: 768px) {
    .slider-text-mobile-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .slider-negative-magrin {
      margin-left: -5vmin;
      margin-right: -5vmin;
      padding-left: 5vmin;
      padding-right: 5vmin;
    }

    .slider-text {
      height: 120px;
    }
  }

  @media (min-width: 1024px) {
    .slider-text {
      height: auto;
    }
  }

  @media (min-width: 1280px) {
    main.container,
    header.container,
    footer .container,
    .Submenu .container {
      padding-left: 80px;
      padding-right: 80px;
    }

    .slider-negative-magrin {
      margin-left: -80px;
      margin-right: -80px;
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  @media (min-width: 1440px) {
    main.container,
    header.container,
    footer .container,
    .Submenu .container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  @media (min-width: 1920px) {
    main.container,
    header.container,
    footer .container,
    .Submenu .container {
      padding-left: 160px;
      padding-right: 160px;
    }

    .slider-negative-magrin {
      margin-left: 0;
      margin-right: 0;
      padding-left: 160px;
      padding-right: 160px;
    }
  }

  @media (max-width: 1280px) {
    .landing-rich-text h2,
    .landing-rich-text p,
    .landing-rich-text h4,
    .landing-rich-text h5 {
      padding: 0;
    }

    main.container,
    header.container,
    footer .container,
    .Submenu .container {
      padding-left: 5vmin;
      padding-right: 5vmin;
    }

    .landing-rich-text.landing-page > p,
    .landing-page .Accordion p {
      width: 100%;
    }

    .landing-rich-text.landing-page > ul {
      margin-left: 15px;
      width: 95%;
    }
  }

  @media (max-width: 767px) {
    main.container,
    header.container,
    footer .container {
      padding-left: 5vmin;
      padding-right: 5vmin;
    }
    .player-container {
      height: 300px;
    }
    .player-container img {
      height: 300px;
    }
  }

  @media (max-width: 500px) {
    h1 {
      font: 2.7rem/3rem 'GTSuperText-Black';
    }
    h2,
    .job-page-content strong {
      font: 1.9rem/2.4rem 'GTSuperText-Black';
    }
    h3 {
      font: 1.5rem/2rem 'GTSuperText-Black';
    }
    .landing-rich-text {
      margin-top: 2rem;
    }
    .landing-rich-text h2 {
      margin: 3rem 0 1rem 0;
    }
    .Accordion h2 {
      margin: 0 0 2rem 0;
    }
  }
}
